<template>
  <v-app>
    <Navigation />
    <v-main>
      <Hero />
      <Solutions />
      <!-- <UnifiedCommunication /> -->
      <EnterpriseSMS />
      <Services />
      <GoodbyeNumbers />
      <Capabilities />
      <!-- <About /> -->
      <Testimonials />
      <CallToAction />
      <PageFooter />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from './Navigation.vue'
import Hero from './Hero.vue'
import Solutions from './Solutions.vue'
import EnterpriseSMS from './EnterpriseSMS.vue'
import Services from './Services.vue'
import GoodbyeNumbers from './GoodbyeNumbers.vue'
import Capabilities from './Capabilities.vue'
import Testimonials from './Testimonials.vue'
import CallToAction from './CallToAction.vue'
import PageFooter from './PageFooter.vue'

export default {
  name: 'EquifaxBrochure',
  components: {
    Navigation,
    Hero,
    Solutions,
    EnterpriseSMS,
    Services,
    GoodbyeNumbers,
    Capabilities,
    Testimonials,
    CallToAction,
    PageFooter,
  },
}
</script>

<style lang='scss'>
.btn-equifax {
    text-transform: none !important;
    background-color: #a00000 !important;
    font-weight: 600;
    font-size: $font-size-root !important;
    letter-spacing: 0px;

    &--outline {
        text-transform: none !important;
        background-color: #fff !important;
        border-color:  #a00000 !important;
        color:   #a00000 !important;
        font-weight: 600 !important;
        font-size: $font-size-root !important;
    }

    &--outline-large {
        text-transform: none !important;
        background-color: #fff !important;
        border-color:  #a00000 !important;
        color:   #a00000 !important;
        font-weight: 600 !important;
        font-size: $font-size-root !important;
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    &--mobile {
        width: 90% !important;
    }
}

.container {
    padding-left: 2rem;
    padding-right: 2rem;

    @include atMedium {
      padding-left: 6rem;
      padding-right: 6rem;
    }

    @include atLarge {
        padding-left: 12rem;
        padding-right: 12rem;
    }

    @include atExtraLarge {
        padding-left: 20rem;
        padding-right: 20rem;
    }
}

.item {
    &__icon {
        margin-bottom: 1.25rem;
    }

    &__header {
        margin-bottom: 0.56rem;
        color: #333e48;
    }

    &__description {
        color: #000;
    }

    &--padding {
        padding-right: 10rem;
    }

    &--grey-card {
        border-radius: 0.625rem;
        padding: 1.37rem 1.18rem 1.93rem;
        background-color: #f8f8f8;
    }

    &--background > span {
        padding: 0.93rem;
        background-color: rgba(158, 27, 50, 0.05);
        border-radius: 50%;
    }
}

.section {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include atLarge {
      padding-top: 6.22rem;
      padding-bottom: 6.22rem;
    }

    &__header-container {
        margin-bottom: 3.12rem;
    }

    &__header-title {
        margin-bottom: 1.87rem;
    }

    &__header-category {
        font-family: 'proxima-nova-xbold', 'Open Sans', sans-serif;
        font-size: 1.25rem;
        font-weight: 800;
        color: #9e1b32;
    }

    &--capabilities {
        padding-top: 6.22rem;
        padding-bottom: 6.22rem;
    }
}
</style>
