<template>
    <div class='goodbye-numbers'>
        <v-container
            class="container pt-7"
            fluid
        >
            <v-row justify='center'>
                <v-col xl='4' lg='4' md='6' col='12' class='my-auto'>
                    <div class='goodbye-numbers__image-container d-lg my-auto'>
                        <v-img
                            src='../assets/number-graph.png'
                            max-width='200px'
                            contain
                        ></v-img>
                    </div>
                </v-col>
                <v-col xl='4' lg='6' md='6' cols='12'>
                    <h2 class='goodbye-numbers__header'>Cloud SMS, built for Enterprise</h2>
                    <div class='goodbye-numbers__content'>
                        <p><strong>23 billion text messages</strong> are sent each day worldwide. Enable your customers to connect with you the way they prefer, and supercharge SMS in your omni-channel strategy.</p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'GoodbyeNumbers',
}
</script>

<style lang='scss'>
.goodbye-numbers {
    background-color: #333e48;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__header {
        color: #fff;
        margin-bottom: 1.5rem;
    }

    &__content {
        & > p {
            color: #fff;
            padding-bottom: 1rem;
            line-height: 1.5;
        }
    }

    &__image-container {
        display: flex;
        margin-bottom: 2rem !important;
        justify-content: center;

        @include atMedium {
            justify-content: center;
        }
    }
}
</style>
