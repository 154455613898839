<template>
    <div class='hero'>
        <video class='hero__video' playsinline autoplay muted loop>
            <source :src='require("@/assets/ee-animation-720.mp4")' type='video/mp4'>
        </video>
        <v-container
            class='container pt-11 mb-6'
            fluid
        >
            <div class='hero__mobile'>
              <v-row>
                  <v-col cols='12'>
                      <v-img
                          src='../assets/mobile-header.png'
                          max-height='600px'
                          contain
                      ></v-img>
                  </v-col>
              </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Hero'
}
</script>

<style lang='scss'>
.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 0;
    width: 100vw;
    height: 75vh;

    &__video {
        visibility: visible;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
        min-width: 1280px;
        display: none; 

        @include atMedium {
            display: block;
        }
    }

    &__mobile {
        text-align: center;
        display: block;

        @include atMedium {
            display: none;
        }
    }
}
</style>
