<template>
    <div class='section' id='services'>
        <v-container
            class="container pt-7"
            fluid
        >
            <div class='section__header-container'>
                <v-row>
                    <v-col xl='5' lg='7' cols='12'>
                        <h2 class='section__header-title'>Automate your Engagement</h2>
                    </v-col>
                </v-row>
            </div>
            <div class='d-xl-flex d-lg-none d-md-none d-sm-none d-none'>
                <SellingPoints
                    :points='this.points'
                    cols='3'
                    colContainerClass='item--padding'
                    iconContainerClass='item__icon item--background'
                    iconSize='80'
                />
            </div>
            <div class='d-xl-none d-lg-flex d-md-none d-sm-none d-none'>
                <SellingPoints
                    :points='this.points'
                    cols='3'
                    iconContainerClass='item__icon item--background'
                    iconSize='80'
                />
            </div>
            <div class='d-xl-none d-lg-none d-md-flex d-sm-flex d-flex justify-center'>
                <SellingPoints
                    :points='this.points'
                    cols='12'
                    colContainerClass='text-center'
                    iconContainerClass='item__icon item--background'
                    iconSize='80'
                />
            </div>
        </v-container>
    </div>
</template>

<script>
import SellingPoints from './SellingPoints.vue'

export default {
    name: 'Services',
    components: {
        SellingPoints
    },
    data: () => ({
        points: [
            {
                icon: '$contactTools',
                title: 'Auto-Comms',
                description: 'Configure rule-based auto-comms such as follow-ups, NPS Surveys, and team notifications',
            },
            // {
            //     icon: '$contactTools',
            //     title: 'Auto-replies',
            //     description: 'Configure rule-based auto-comms between systems and teams',
            // },
            // {
            //     icon: '',
            //     title: 'Auto-replies',
            //     description: 'Configure rule-based auto-comms between systems and teams',
            // },
            // {
            //     icon: '$contactTools',
            //     title: 'Contact tools',
            //     description: "Equifax Engage boasts a range of tools to help engage Customers and keep them engaged.",
            // },
            {
                icon: '$workflow',
                title: 'Workflows',
                description: "Workflows allow you to build a Communication Strategy that maximises Customer Engagement",
            },
            {
                icon: '$phones',
                title: 'Dynamic Chat Routing',
                description: "Route your inbound communication based on your business rules, so your Customers always end up in the right place.",
            },
            {
                icon: '$chatBot',
                title: 'Chatbots',
                description: "AI enabled chatbots assist customers with simple tasks, while your team focuses on complicated interactions.",
            },
            // {
            //     icon: '$survey',
            //     title: 'Surveys',
            //     description: "SMS and Web Surveys",
            // },
        ],
    }),
}
</script>
