import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import BankingIcon from './icons/BankingIcon.vue';
import CollectionsIcon from './icons/CollectionsIcon.vue';
import ContactCentresIcon from './icons/ContactCentresIcon.vue';
import CustomerServiceIcon from './icons/CustomerServiceIcon.vue';
import EnterpriseIcon from './icons/EnterpriseIcon.vue';
import PeerIcon from './icons/PeerIcon.vue';
import RetailIcon from './icons/RetailIcon.vue';
import TranslateIcon from './icons/TranslateIcon.vue';
import ChatBotIcon from './icons/ChatBotIcon.vue';
import ContactToolsIcon from './icons/ContactToolsIcon.vue';
import PhonesIcon from './icons/PhonesIcon.vue';
import PlatformIcon from './icons/PlatformIcon.vue';
import SurveyIcon from './icons/SurveyIcon.vue';
import WorkflowIcon from './icons/WorkflowIcon.vue';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        values: {
            banking: {
                component: BankingIcon,
            },
            collections: {
                component: CollectionsIcon,
            },
            contactCentres: {
                component: ContactCentresIcon,
            },
            customerService: {
                component: CustomerServiceIcon,
            },
            enterprise: {
                component: EnterpriseIcon,
            },
            peer: {
                component: PeerIcon,
            },
            retail: {
                component: RetailIcon,
            },
            translate: {
                component: TranslateIcon,
            },
            chatBot: {
                component: ChatBotIcon,
            },
            contactTools: {
                component: ContactToolsIcon,
            },
            phones: {
                component: PhonesIcon,
            },
            platform: {
                component: PlatformIcon,
            },
            survey: {
                component: SurveyIcon,
            },
            workflow: {
                component: WorkflowIcon,
            },
        }
    }
});
