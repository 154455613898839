<template>
    <svg width="42px" height="43px" viewBox="0 0 42 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>contact-tools</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-695.000000, -95.000000)" stroke="#9E1B32">
                <g id="contact-tools" transform="translate(696.000000, 96.000000)">
                    <g id="Group-2">
                        <path d="M31,0 C33.209139,-4.05812251e-16 35,1.790861 35,4 L35,22.1219512 L35,22.1219512 L27,22.1219512 L27,22.1219512 C23.6666667,19.5203252 22,18.5447154 22,19.195122 L22,18 C22,17.3333333 21.6666667,17 21,17 L21,17 L20,17 C19,17 18,18 18,19 L18,19 L18.0007549,26.5040864 C17.6719398,26.5724933 17.3383549,26.6419017 17,26.7123117 L13.011,26.712 L12.1029653,31 L7.688,26.712 L4,26.7123117 C1.790861,26.7123117 2.705415e-16,24.9214507 0,22.7123117 L0,4 C-2.705415e-16,1.790861 1.790861,4.05812251e-16 4,0 L31,0 Z" id="Rectangle-Copy-15" stroke-width="2"></path>
                        <rect id="Rectangle-Copy-18" stroke-width="1.5" x="4.75" y="15.75" width="9.5" height="1"></rect>
                        <rect id="Rectangle-Copy-19" stroke-width="1.5" x="4.75" y="20.75" width="9.5" height="1"></rect>
                        <circle id="Oval-Copy-2" stroke-width="1.5" cx="8.5" cy="9.5" r="1.75"></circle>
                        <circle id="Oval-Copy-35" stroke-width="1.5" cx="26.5" cy="9.5" r="1.75"></circle>
                        <circle id="Oval-Copy-36" stroke-width="1.5" cx="17.5" cy="9.5" r="1.75"></circle>
                    </g>
                    <g id="Group-2" transform="translate(18.000000, 17.000000)">
                        <rect id="Rectangle" stroke-width="2" x="19" y="8" width="3" height="4" rx="1.5"></rect>
                        <path d="M19,8.04878049 C19,6.09756098 18.3333333,5.12195122 17,5.12195122 C15.6666667,5.12195122 13,5.12195122 9,5.12195122 C5.66666667,2.5203252 4,1.54471545 4,2.19512195 C4,3.17073171 4,16.8292683 4,17.804878 C4,18.4552846 5.66666667,17.4796748 9,14.8780488 L17,14.8780488 C18.3333333,14.8780488 19,13.902439 19,11.9512195 C19,10 19,8.69918699 19,8.04878049 Z" id="Path-3" stroke-width="2"></path>
                        <line x1="9" y1="5" x2="9" y2="15" id="Path-6" stroke-width="2"></line>
                        <path d="M17,15 L17,23 C17,23.6666667 16.6666667,24 16,24 C15.3333333,24 14.6666667,24 14,24 C13.3333333,24 13,23.6666667 13,23 C13,22.3333333 13,19.6666667 13,15 L17,15 Z" id="Path-5" stroke-width="2"></path>
                        <polyline id="Path-7" stroke-width="1.5" points="13 9 16 9 16 12"></polyline>
                        <path d="M4,1 C4,1.66666667 4,7.33333333 4,18 C4,19.3333333 3.66666667,20 3,20 C2,20 3,20 2,20 C1,20 0,19 0,18 C0,17 0,3 0,2 C0,1 1,0 2,0 C2.66666667,0 3,0 3,0 C3.66666667,0 4,0.333333333 4,1 Z" id="Path-8" stroke-width="2"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'ContactToolsIcon',
}
</script>