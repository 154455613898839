<template>
    <svg width="52px" height="43px" viewBox="0 0 52 43" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>survey</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-800.000000, -223.000000)">
                <g id="survey" transform="translate(800.000000, 223.000000)">
                    <g id="bubble1">
                        <path d="M39,1 C39.8284271,1 40.5784271,1.33578644 41.1213203,1.87867966 C41.6642136,2.42157288 42,3.17157288 42,4 L42,4 L42,21.9796063 C42,22.8080334 41.6642136,23.5580334 41.1213203,24.1009266 C40.5784271,24.6438199 39.8284271,24.9796063 38.999939,24.9796063 L38.999939,24.9796063 L35.5359197,24.9798201 C34.9526885,24.9811651 34.4854933,24.9824009 34.057359,24.9835334 C33.7932949,24.9842319 33.5440971,24.9848911 33.291881,24.9855119 L33.291881,24.9855119 L32.6295629,24.9870155 L30.7221079,24.9881213 C30.4973051,24.9875984 30.3147899,24.9866838 30.1915272,24.985226 L30.1915272,24.985226 L29.2382006,24.9739518 L29.0578309,28 L11.7310666,28 L9.04098757,32.3585379 L5.85467218,28 L4,28 C3.17157288,28 2.42157288,27.6642136 1.87867966,27.1213203 C1.33578644,26.5784271 1,25.8284271 1,25 L1,25 L1,4 C1,3.17157288 1.33578644,2.42157288 1.87867966,1.87867966 C2.42157288,1.33578644 3.17157288,1 4,1 L4,1 Z" id="Rectangle-Copy-9" stroke="#9E1B32" stroke-width="2"></path>
                        <rect id="Rectangle" fill="#9E1B32" x="14" y="7" width="21" height="2"></rect>
                        <rect id="Rectangle-Copy-11" fill="#9E1B32" x="14" y="14" width="15" height="2"></rect>
                        <rect id="Rectangle-Copy-12" fill="#9E1B32" x="14" y="21" width="11" height="2"></rect>
                        <circle id="Oval" stroke="#9E1B32" stroke-width="1.4" cx="9.11111111" cy="8.11111111" r="1.41111111"></circle>
                        <circle id="Oval-Copy-33" stroke="#9E1B32" stroke-width="1.4" cx="9.11111111" cy="21.8888889" r="1.41111111"></circle>
                        <circle id="Oval-Copy-32" stroke="#9E1B32" stroke-width="1.4" cx="9.11111111" cy="15.1111111" r="1.41111111"></circle>
                    </g>
                    <g id="bubble2" transform="translate(28.000000, 24.000000)">
                        <path d="M22,1 C22.2761424,1 22.5261424,1.11192881 22.7071068,1.29289322 C22.8880712,1.47385763 23,1.72385763 23,2 L23,2 L23,12.753536 C23,13.0296784 22.8880712,13.2796784 22.7071068,13.4606428 C22.5261424,13.6416072 22.2761424,13.753536 22.0000464,13.753536 L22.0000464,13.753536 L9.93635347,13.7529758 L7.8910668,16.6846951 L5.57539121,13.752916 L2,13.753536 C1.72385763,13.753536 1.47385763,13.6416072 1.29289322,13.4606428 C1.11192881,13.2796784 1,13.0296784 1,12.753536 L1,12.753536 L1,2 C1,1.72385763 1.11192881,1.47385763 1.29289322,1.29289322 C1.47385763,1.11192881 1.72385763,1 2,1 L2,1 Z" id="Rectangle-Copy-10" stroke="#9E1B32" stroke-width="2" transform="translate(12.000000, 9.180541) scale(-1, 1) translate(-12.000000, -9.180541) "></path>
                        <rect id="Rectangle-Copy-13" fill="#9E1B32" x="11" y="6" width="8" height="2"></rect>
                        <circle id="Oval-Copy-34" stroke="#9E1B32" stroke-width="1.4" cx="7.11111111" cy="7.11111111" r="1.41111111"></circle>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'SurveyIcon',
}
</script>