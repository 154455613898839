<template>
    <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>peer</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-156.000000, -419.000000)" fill="#9E1B32" fill-rule="nonzero">
                <g id="peer" transform="translate(156.000000, 419.000059)">
                    <g id="Group" transform="translate(27.690084, 0.279768)">
                        <path d="M29.6002387,56.0557161 C29.6002387,56.5827871 29.172471,57.0105548 28.6454,57.0105548 L9.54862579,57.0105548 C9.02155483,57.0105548 8.59378707,56.5827871 8.59378707,56.0557161 L8.59378707,25.5008774 C8.59378707,24.9747613 9.02155483,24.5460387 9.54862579,24.5460387 L28.6454,24.5460387 C29.172471,24.5460387 29.6002387,24.9747613 29.6002387,25.5008774 L29.6002387,56.0557161 Z M28.6454,22.6363613 L21.9128323,22.6363613 C21.4296839,12.4530064 13.3345613,4.25189678 3.19512903,3.60439678 L5.44950322,1.35014194 L4.09936129,0 L0.280006452,3.81935484 C-0.0933354839,4.19269678 -0.0933354839,4.79615484 0.280006452,5.16949678 L4.09936129,8.98885163 L5.44950322,7.63870967 L3.33262581,5.52087742 C12.3558516,6.22650323 19.5276452,13.5509516 20.0041097,22.6363613 L9.54862579,22.6363613 C7.96932259,22.6363613 6.68410968,23.9215742 6.68410968,25.5008774 L6.68410968,56.0557161 C6.68410968,57.6349 7.96932259,58.9202323 9.54862579,58.9202323 L28.6454,58.9202323 C30.2247032,58.9202323 31.5099161,57.6349 31.5099161,56.0557161 L31.5099161,25.5008774 C31.5099161,23.9215742 30.2247032,22.6363613 28.6454,22.6363613 L28.6454,22.6363613 Z" id="Shape"></path>
                    </g>
                    <polygon id="Path" points="45.8322581 55.3805975 47.7419355 55.3805975 47.7419355 53.47092 45.8322581 53.47092"></polygon>
                    <path d="M40.1032258,49.6515651 L53.4709678,49.6515651 L53.4709678,28.6451135 L40.1032258,28.6451135 L40.1032258,49.6515651 Z M54.4258065,26.7354361 L39.1483871,26.7354361 C38.6203613,26.7354361 38.1935484,27.1632039 38.1935484,27.6902748 L38.1935484,50.6064039 C38.1935484,51.1344297 38.6203613,51.5612426 39.1483871,51.5612426 L54.4258065,51.5612426 C54.9538322,51.5612426 55.3806452,51.1344297 55.3806452,50.6064039 L55.3806452,27.6902748 C55.3806452,27.1632039 54.9538322,26.7354361 54.4258065,26.7354361 L54.4258065,26.7354361 Z" id="Shape"></path>
                    <polygon id="Path" points="11.4580645 32.4644684 13.3677419 32.4644684 13.3677419 30.554791 11.4580645 30.554791"></polygon>
                    <path d="M5.72903226,26.7354361 L19.0967742,26.7354361 L19.0967742,5.72898451 L5.72903226,5.72898451 L5.72903226,26.7354361 Z M20.0516129,3.8193071 L4.77419355,3.8193071 C4.24616774,3.8193071 3.81935484,4.24707484 3.81935484,4.77414581 L3.81935484,27.6902748 C3.81935484,28.2173458 4.24616774,28.6451135 4.77419355,28.6451135 L20.0516129,28.6451135 C20.5796387,28.6451135 21.0064516,28.2173458 21.0064516,27.6902748 L21.0064516,4.77414581 C21.0064516,4.24707484 20.5796387,3.8193071 20.0516129,3.8193071 L20.0516129,3.8193071 Z" id="Shape"></path>
                    <path d="M1.90967742,33.4193548 L1.90967742,2.86451613 C1.90967742,2.3384 2.33744516,1.90967742 2.86451613,1.90967742 L21.9612903,1.90967742 C22.4883613,1.90967742 22.916129,2.3384 22.916129,2.86451613 L22.916129,33.4193548 C22.916129,33.9464258 22.4883613,34.3741935 21.9612903,34.3741935 L2.86451613,34.3741935 C2.33744516,34.3741935 1.90967742,33.9464258 1.90967742,33.4193548 L1.90967742,33.4193548 Z M27.4105548,49.9313807 L26.0604129,51.2814032 L28.1782452,53.3992355 C19.1550194,52.6936097 11.9832258,45.3691613 11.5067613,36.283871 L21.9612903,36.283871 C23.5405935,36.283871 24.8258065,34.9985387 24.8258065,33.4193548 L24.8258065,2.86451613 C24.8258065,1.28521291 23.5405935,0 21.9612903,0 L2.86451613,0 C1.2852129,0 0,1.28521291 0,2.86451613 L0,33.4193548 C0,34.9985387 1.2852129,36.283871 2.86451613,36.283871 L9.59708384,36.283871 C10.0802322,46.466271 18.1753548,54.6682161 28.3147871,55.3157161 L26.0604129,57.5700903 L27.4105548,58.9201129 L31.2299097,55.1007581 C31.6032516,54.7274161 31.6032516,54.1240775 31.2299097,53.7507355 L27.4105548,49.9313807 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'PeerIcon',
}
</script>