<template>
    <div class='section gray' id="enterprise-sms">
        <v-container
            class="container pt-7"
            fluid
        >
            <v-row justify='center'>
              <v-col xl='4' lg='6' md='6' col='12' class='my-auto'>
                  <div class='enterprise-sms__image-container d-lg my-auto'>
                      <v-img
                          src='../assets/send-syntax.png'
                          max-width='360px'
                          contain
                      ></v-img>
                  </div>
              </v-col>
                <v-col xl='5' lg='6' md='6' cols='12'>
                    <h2 class='enterprise-sms__header'>APIs for reliable messaging</h2>
                    <div class='enterprise-sms__content'>
                      <v-list-item class='three-line'>
                        <v-icon class='mr-2'>mdi-check-circle</v-icon>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">Integrate Engage into your backend systems using our comprehensive API.</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-icon class='mr-2'>mdi-check-circle</v-icon>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">Developer friendly <a href="https://api.equifaxengage.com/#/2019-01-23/messages/messages-post">documentation</a> empowers your team to integrate quickly.</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-icon class='mr-2'>mdi-check-circle</v-icon>
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap">Fast, reliable delivery across SMS, Email and Voice Calls.</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'EnterpriseSMS',
}
</script>

<style lang='scss'>
.enterprise-sms {
    &__header {
        margin-bottom: 1.5rem;
    }

    &__content {
        & > p {
            padding-bottom: 1rem;
            line-height: 1.5;
        }

        .v-list-item .v-list-item__title, .v-list-item .v-list-item__subtitle {
          line-height: 1.5 !important;
        }

        .v-icon {
          color: #16bd78;
        }
    }

    &__image-container {
        display: flex;
        margin-bottom: 2rem !important;
        justify-content: center;

        @include atMedium {
            justify-content: center;
        }
    }
}
</style>
