<template>
    <div class='section' id='solutions'>
        <v-container
            class="container"
            fluid
        >
            <div class='section__header-container'>
                <v-row>
                    <v-col xl='6' lg='7' cols='12'>
                        <h2 class='section__header-title'>Scalable solutions for every industry</h2>
                    </v-col>
                </v-row>
            </div>
            <div class='d-xl-flex d-lg-flex d-md-none d-sm-none d-none'>
                <SellingPoints
                    :points='this.points'
                    :cols='3'
                    iconSize='60'
                />
            </div>
            <div class='d-xl-none d-lg-none d-md-flex d-sm-flex d-flex justify-center'>
                <SellingPoints
                    :points='this.points'
                    :cols='12'
                    colContainerClass='text-center'
                    iconSize='60'
                />
            </div>
        </v-container>
    </div>
</template>

<script>
import SellingPoints from './SellingPoints.vue'

export default {
    name: 'Solutions',
    components: {
        SellingPoints,
    },
    data: () => ({
        points: [
            {
                icon: '$translate',
                title: 'Multilingual',
                description: "Connect with your customers across 40+ languages using Equifax Engage's integrated translation service",
            },
            {
                icon: '$collections',
                title: 'Collections',
                description: "Conversations are migrating to text-based mediums. Equifax Engage ensures your company is ready for the shift.",
            },
            {
                icon: '$enterprise',
                title: 'Enterprise',
                description: "Equifax Engage scales to suit your needs. The integration of chatbots will dramatically improve productivity through automation.",
            },
            {
                icon: '$customerService',
                title: 'Customer service',
                description: "Providing more mediums enables Customers to engage with your business in the way that best suits the indiviudal.",
            },
            {
                icon: '$contactCentres',
                title: 'Contact centres',
                description: "Equifax Engage was born in the Contact Centre industry and has a range of purpose-built features to enrich the Customer's experience.",
            },
            {
                icon: '$peer',
                title: 'Peer-2Peer',
                description: "Empower your team to engage members and donors directly via SMS, Web Chat or Email.",
            },
            {
                icon: '$banking',
                title: 'Banking',
                description: "Equifax Engage is 100% Australian based, ensures data sovereignty and has a range of privacy and authorisation tools.",
            },
            {
                icon: '$retail',
                title: 'Retail',
                description: "More channels create more opportunities. Coupled with reminder tools, marketing campaigns and chatbots, Equifax Engage is retail ready.",
            },
        ],
    }),
}
</script>
