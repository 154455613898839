<template>
    <svg width="45px" height="60px" viewBox="0 0 45 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>collections</title>
        <defs>
            <filter color-interpolation-filters="auto" id="filter-1">
                <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.619608 0 0 0 0 0.105882 0 0 0 0 0.196078 0 0 0 1.000000 0"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="collections" transform="translate(-156.000000, -307.000000)" filter="url(#filter-1)">
                <g transform="translate(156.000000, 307.000000)">
                    <path d="M8.13596491,9.37499997 C8.77754383,9.37499997 9.29824558,8.84999997 9.29824558,8.203125 C9.29824558,7.55625 8.77754383,7.03125 8.13596491,7.03125 C7.49438597,7.03125 6.97368421,7.55625 6.97368421,8.203125 C6.97368421,8.84999997 7.49438597,9.37499997 8.13596491,9.37499997" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M8.13596491,16.40625 C8.77754383,16.40625 9.29824558,15.88125 9.29824558,15.234375 C9.29824558,14.5875 8.77754383,14.0625 8.13596491,14.0625 C7.49438597,14.0625 6.97368421,14.5875 6.97368421,15.234375 C6.97368421,15.88125 7.49438597,16.40625 8.13596491,16.40625" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <g id="Group" fill="#9E1B32" fill-rule="nonzero">
                        <path d="M2.3245614,3.515625 C2.3245614,2.86921875 2.84572807,2.34375 3.48684211,2.34375 L27.8947368,2.34375 L27.8947368,15.234375 C27.8947368,15.8816016 28.4150899,16.40625 29.0570176,16.40625 L41.8421052,16.40625 L41.8421052,56.484375 C41.8421052,57.1307812 41.3209386,57.65625 40.6798246,57.65625 L3.48684211,57.65625 C2.84572807,57.65625 2.3245614,57.1307812 2.3245614,56.484375 L2.3245614,3.515625 Z M30.2192982,4.00136719 L40.1985241,14.0625 L30.2192982,14.0625 L30.2192982,4.00136719 Z M3.48684211,60 L40.6798246,60 C42.6025855,60 44.1666667,58.4230078 44.1666667,56.484375 L44.1666667,15.234375 C44.1666667,14.9157422 44.0354452,14.6164453 43.8261184,14.4058594 L29.87875,0.343359375 C29.6703531,0.133242187 29.3735066,0 29.0570176,0 L3.48684211,0 C1.56408114,0 0,1.57699219 0,3.515625 L0,56.484375 C0,58.4230078 1.56408114,60 3.48684211,60 L3.48684211,60 Z" id="Shape"></path>
                    </g>
                    <path d="M8.13596491,24.140625 L36.0307018,24.140625 C36.6726294,24.140625 37.1929824,23.6159766 37.1929824,22.96875 C37.1929824,22.3215235 36.6726294,21.796875 36.0307018,21.796875 L8.13596491,21.796875 C7.49403728,21.796875 6.97368421,22.3215235 6.97368421,22.96875 C6.97368421,23.6159766 7.49403728,24.140625 8.13596491,24.140625" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M8.13596491,31.1719336 L36.0307018,31.1719336 C36.6726294,31.1719336 37.1929824,30.6472851 37.1929824,30.0000586 C37.1929824,29.3527149 36.6726294,28.8281836 36.0307018,28.8281836 L8.13596491,28.8281836 C7.49403728,28.8281836 6.97368421,29.3527149 6.97368421,30.0000586 C6.97368421,30.6472851 7.49403728,31.1719336 8.13596491,31.1719336" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M12.7850878,9.37499997 L22.0833333,9.37499997 C22.725261,9.37499997 23.245614,8.85035155 23.245614,8.203125 C23.245614,7.55589844 22.725261,7.03125 22.0833333,7.03125 L12.7850878,7.03125 C12.1431601,7.03125 11.622807,7.55589844 11.622807,8.203125 C11.622807,8.85035155 12.1431601,9.37499997 12.7850878,9.37499997" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M12.7850878,16.40625 L22.0833333,16.40625 C22.725261,16.40625 23.245614,15.8816016 23.245614,15.234375 C23.245614,14.5871485 22.725261,14.0625 22.0833333,14.0625 L12.7850878,14.0625 C12.1431601,14.0625 11.622807,14.5871485 11.622807,15.234375 C11.622807,15.8816016 12.1431601,16.40625 12.7850878,16.40625" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M33.7061403,42.8906836 C33.0650263,42.8906836 32.5438597,42.3652148 32.5438597,41.7188086 C32.5438597,41.0724024 33.0650263,40.5469336 33.7061403,40.5469336 C34.1179364,40.5469336 34.5523969,40.7589258 34.9632632,41.1594726 C35.4245723,41.6093555 36.1600636,41.5970508 36.6068443,41.1324023 C37.0531601,40.6672851 37.0408399,39.9252539 36.5796469,39.4752539 C35.9897895,38.8993946 35.3995833,38.5739649 34.8684211,38.3963086 L34.8684211,37.0313086 C34.8684211,36.3840821 34.348068,35.8594336 33.7061403,35.8594336 C33.0642127,35.8594336 32.5438597,36.3840821 32.5438597,37.0313086 L32.5438597,38.4046289 C31.1908487,38.8884961 30.2192982,40.1912696 30.2192982,41.7188086 C30.2192982,43.6574414 31.7833794,45.2344336 33.7061403,45.2344336 C34.3472544,45.2344336 34.8684211,45.7599024 34.8684211,46.4063086 C34.8684211,47.0527148 34.3472544,47.5781836 33.7061403,47.5781836 C33.2094978,47.5781836 32.6736864,47.2633008 32.1970351,46.691543 C31.7838443,46.1961914 31.0505614,46.1320899 30.5593816,46.5486914 C30.0680855,46.965293 30.0045087,47.7045117 30.4176996,48.1998633 C31.0388223,48.9450586 31.7697807,49.4641992 32.5438597,49.7259961 L32.5438597,51.0938086 C32.5438597,51.7410351 33.0642127,52.2656836 33.7061403,52.2656836 C34.348068,52.2656836 34.8684211,51.7410351 34.8684211,51.0938086 L34.8684211,49.7204883 C36.221432,49.2366211 37.1929824,47.9338476 37.1929824,46.4063086 C37.1929824,44.4676758 35.6289013,42.8906836 33.7061403,42.8906836" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M8.13596491,38.2031836 L24.4078948,38.2031836 C25.0498223,38.2031836 25.5701754,37.6785351 25.5701754,37.0313086 C25.5701754,36.3840821 25.0498223,35.8594336 24.4078948,35.8594336 L8.13596491,35.8594336 C7.49403728,35.8594336 6.97368421,36.3840821 6.97368421,37.0313086 C6.97368421,37.6785351 7.49403728,38.2031836 8.13596491,38.2031836" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M8.13596491,45.2344336 L24.4078948,45.2344336 C25.0498223,45.2344336 25.5701754,44.7097851 25.5701754,44.0625586 C25.5701754,43.4153321 25.0498223,42.8906836 24.4078948,42.8906836 L8.13596491,42.8906836 C7.49403728,42.8906836 6.97368421,43.4153321 6.97368421,44.0625586 C6.97368421,44.7097851 7.49403728,45.2344336 8.13596491,45.2344336" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                    <path d="M8.13596491,52.2656836 L24.4078948,52.2656836 C25.0498223,52.2656836 25.5701754,51.7410351 25.5701754,51.0938086 C25.5701754,50.4465821 25.0498223,49.9219336 24.4078948,49.9219336 L8.13596491,49.9219336 C7.49403728,49.9219336 6.97368421,50.4465821 6.97368421,51.0938086 C6.97368421,51.7410351 7.49403728,52.2656836 8.13596491,52.2656836" id="Path" fill="#1582D8" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'CollectionsIcon',
}
</script>