<template>
    <svg width="62px" height="60px" viewBox="15 1 30 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>phones</title>
        <defs>
            <polygon id="path-1" points="5.02683117 0 0 0 0 36.9285906 11.0935714 37.9691275 11.0935714 15.0837584 14.0401818 13.6969128 22.7068961 13.6969128 22.7068961 0"></polygon>
            <polygon id="path-3" points="2.08166817e-16 -1.67921232e-15 2.08166817e-16 36.9281879 11.093974 37.9683221 11.093974 15.082953 14.0401818 13.6961074 22.7072987 13.6961074 22.7072987 -1.67921232e-15"></polygon>
            <polygon id="path-5" points="0 0 0 13.6961074 8.66671429 13.6961074 11.6137273 15.082953 11.6137273 37.9683221 22.7068961 36.9281879 22.7068961 0"></polygon>
            <polygon id="path-7" points="17.6804675 0 0 0 0 13.6969128 8.66671429 13.6969128 11.6137273 15.0837584 11.6137273 37.9691275 22.7068961 36.9285906 22.7068961 0"></polygon>
            <polygon id="path-9" points="-2.22044605e-16 1.66533454e-16 20.0956494 1.66533454e-16 20.0956494 12.9169128 -2.22044605e-16 12.9169128"></polygon>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-427.000000, -194.000000)">
                <g id="Group-18" transform="translate(427.074481, 182.403088)">
                    <path d="M30.8856624,58.37557 C30.2382857,58.37557 29.7181299,57.8540935 29.7181299,57.2029526 C29.7181299,56.5614761 30.2382857,56.0351673 30.8856624,56.0351673 C31.5318312,56.0351673 32.0531948,56.5614761 32.0531948,57.2029526 C32.0531948,57.8540935 31.5318312,58.37557 30.8856624,58.37557 L30.8856624,58.37557 Z M39.3063896,28.6518116 L39.3063896,55.2197311 L22.4637273,55.2197311 L22.4637273,28.6518116 L39.3063896,28.6518116 Z M33.3515714,26.6287244 C33.511,26.6287244 33.6358052,26.7535566 33.6358052,26.9130197 C33.6358052,27.0676506 33.511,27.1936908 33.3515714,27.1936908 L28.4197533,27.1936908 C28.2603247,27.1936908 28.1343117,27.0676506 28.1343117,26.9130197 C28.1343117,26.7535566 28.2603247,26.6287244 28.4197533,26.6287244 L33.3515714,26.6287244 Z M38.5974156,24.9446975 L23.1739091,24.9446975 C21.8904286,24.9446975 20.838039,25.9912747 20.838039,27.280268 L20.838039,57.2029526 C20.838039,58.4915432 21.8904286,59.5445633 23.1739091,59.5445633 L38.5974156,59.5445633 C39.8808961,59.5445633 40.9336883,58.4915432 40.9336883,57.2029526 L40.9336883,27.280268 C40.9336883,25.9912747 39.8808961,24.9446975 38.5974156,24.9446975 L38.5974156,24.9446975 Z" id="Fill-1" fill="#9E1B32" fill-rule="nonzero"></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'PhonesIcon',
}
</script>