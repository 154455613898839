<template>
  <div id="app">
    <EquifaxBrochure />
  </div>
</template>

<script>
import EquifaxBrochure from './components/EquifaxBrochure.vue';
import widgetExec from './widgetExec';

export default {
  name: 'App',
  components: {
    EquifaxBrochure
  },
  mounted: function () {
    const org = '64451698af5e2e8adde3c11394ea624b9b310ca05dbe9aa80df09cb768b7d79f';
    const widgetSrc = 'https://chat.equifaxengage.com/loader.js';
    widgetExec(window, document, org, {}, widgetSrc);
  }
}
</script>
