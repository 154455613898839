<template>
    <div class='testimonials gray'>
        <v-container
            class="container pt-7"
            fluid>
            <div class='section__header-container'>
                <v-row justify='center'>
                    <v-col xl='8' lg='10' cols='12'>
                        <h2 class='section__header-title text-center'>Testimonials</h2>
                        <v-carousel
                            v-model='model'
                            cycle
                            hide-delimiter-background
                            :show-arrows='false'
                            height='auto'
                            class='testimonials__carousel'>
                            <v-carousel-item
                                v-for='(testimonial, i) in testimonials'
                                :key='i'>
                                <!-- <v-icon class='testimonials__quotation'>mdi-format-quote-open</v-icon> -->
                                <div class='d-flexpt-6 px-16 mb-6 quote'>
                                  "{{ testimonial.quote }}"
                                </div>
                                <div class="d-flex pb-16 px-16 mb-6 author">
                                  - {{ testimonial.author }}
                                </div>
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Testimonials',
    data: () => ({
        model: 0,
        testimonials: [
          {
            quote: 'Communicating with our members can be challenging. Equifax Engage helps us to do this in new ways which are interactive and personal.',
            author: 'Jake Araullo, United Workers',
          },
          {
            quote: 'We were looking for a way to reach a greater audience with our market research programs. Equifax Engage’s Survey platform is cost-effective, easy to manage, and intuitive to use.',
            author: 'Mikkel Brezhnev, uComms',
          },
          {
            quote: 'Equifax Engage has revolutionised the call centre industry, giving customers a voice! Live translation of over 40 languages has helped us break down barriers with our customer base.',
            author: 'Dawn Donelly, Panthera Finance',
          },
          {
            quote: 'Introducing a combined 2-way SMS, Web Chat, and Email platform to our business saw a 52% rise in response rates.',
            author: 'Katie McRae, Bill Chaser',
          },
        ],
  }),
}
</script>

<style lang='scss'>
.testimonials {
    background-color: #f5f7fa;
    padding-top: 2rem;

    &__quotation {
        font-size: 5rem !important;
        color: #9e1b32 !important;
    }

    &__carousel {
        & .v-carousel__controls__item .v-icon {
            opacity: 1;
        }

        & .v-carousel__controls__item.v-btn {
            color: #F1ADBA;
            opacity: 0.25;
        }

        & .v-carousel__controls__item.v-btn.v-btn--active {
            color: #9e1b32;
            opacity: 1;
        }

        & .v-carousel__controls__item.v-btn.v-btn--active:before {
            opacity: 0;
        }

        & .v-carousel__controls__item.v-btn:hover {
            color: #9e1b32;
        }

        & .v-carousel__controls__item.v-btn:hover:before {
            opacity: 0;
        }
    }

    .quote {
      font-size: 1.1rem;

      @include atMedium {
        font-size: 1.2rem;
      }

      @include atLarge {
        font-size: 1.4rem;
      }

      @include atLarge {
        font-size: 1.4rem;
      }

      @include atExtraLarge {
        font-size: 1.4rem;
      }
    }

    .author {
      font-weight: 700;
    }
}
</style>
