<template>
    <svg width="60px" height="60px" viewBox="0 0 60 60" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>translate</title>
        <defs>
            <filter color-interpolation-filters="auto" id="filter-1">
                <feColorMatrix in="SourceGraphic" type="matrix" values="0 0 0 0 0.619608 0 0 0 0 0.105882 0 0 0 0 0.196078 0 0 0 1.000000 0"></feColorMatrix>
            </filter>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="translate" transform="translate(-44.000000, -307.000000)" filter="url(#filter-1)">
                <g transform="translate(44.000000, 307.000012)">
                    <g id="Group_3" fill="#1582D8" fill-rule="nonzero">
                        <path d="M19.5526816,17.2109295 L16.8915278,14.5821902 L16.923547,14.5501709 C18.6983241,12.5774897 20.0244276,10.2435584 20.8105235,7.70912393 L23.8804167,7.70912393 L23.8804167,5.60692308 L16.545641,5.60692308 C16.545641,5.60692308 17.0694124,4.03442308 16.545641,3.51183761 C15.9454351,2.98820276 15.0507614,2.98820276 14.4505556,3.51183761 C13.9267842,4.03560897 14.4505556,5.60692308 14.4505556,5.60692308 L7.11538462,5.60692308 L7.11538462,7.69212607 L18.8201923,7.69212607 C18.0967376,9.7658101 16.9679956,11.6748622 15.4996795,13.308141 C14.5441818,12.2476162 13.7301979,11.0676984 13.0780769,9.79788462 L10.9829915,9.79788462 C11.7690459,11.5436644 12.8226715,13.1561117 14.1058547,14.5770513 L8.77564103,19.8396688 L10.2580128,21.3220406 L15.4980983,16.0843269 L18.7573397,19.3427778 L19.5538675,17.2109295 L19.5526816,17.2109295 Z" id="Path_2"></path>
                        <path d="M3.82926282,0 L26.8056303,0 C28.9196089,0.00239540006 30.6328519,1.71528485 30.6356838,3.82926282 L30.6356838,21.0615385 C30.6328529,23.1752082 28.9200907,24.8879704 26.8064209,24.8908013 L21.0615385,24.8908013 L21.0615385,29.9170299 C21.0622763,30.1077169 20.9869068,30.2908182 20.852144,30.4257295 C20.7173811,30.5606407 20.5343629,30.6362117 20.3436752,30.6356999 C20.1899623,30.6367383 20.0403362,30.5862134 19.9187286,30.4921902 L12.4455983,24.8908013 L3.82926282,24.8908013 C1.71559305,24.8879704 0.00283086014,23.1752082 0,21.0615385 L0,3.82926282 C0.00283086014,1.71559305 1.71559305,0.00283086014 3.82926282,0 Z M1.91482906,21.0615385 C1.9178687,22.1178077 2.77338885,22.9733279 3.82965812,22.9763675 L13.0844017,22.9763675 L13.5931517,23.3594124 L19.1467094,27.5234936 L19.1467094,22.9775534 L26.8056303,22.9775534 C27.8618996,22.9745138 28.7174198,22.1189936 28.7204594,21.0627244 L28.7204594,3.82926282 C28.7174198,2.77299356 27.8618996,1.9174734 26.8056303,1.91443376 L3.82926282,1.91443376 C2.77314793,1.91769036 1.91786761,2.77314728 1.91482906,3.82926282 L1.91482906,21.0615385 Z" id="comment-alt-minus-light"></path>
                    </g>
                    <g id="Group_2" transform="translate(29.166667, 29.166667)" fill="#1582D8" fill-rule="nonzero">
                        <g id="Group_1" transform="translate(8.190359, 4.400470)">
                            <path d="M0.00498317112,14.6912927 L5.39607291,0.776762821 C5.58384001,0.285010684 5.957793,0 7.05949172,0 C8.18411779,0 8.53514343,0.28224359 8.72291052,0.776762821 L14.1594597,14.6912927 C14.2306135,14.8790598 13.5510943,15.4174573 12.8237439,15.4174573 C12.2853464,15.4174573 11.7236263,15.1585363 11.4006669,14.2924359 L10.0566498,10.8493803 L3.98801736,10.8493803 L2.65230155,14.3169444 C2.34792121,15.1597222 1.83284642,15.417062 1.31658574,15.417062 C0.613348556,15.4194338 -0.0649847776,14.9023825 0.00498317112,14.6912927 Z M9.44630796,8.90411325 L7.01007932,2.48524573 L4.5726648,8.90411325 L9.44630796,8.90411325 Z" id="Path_1"></path>
                        </g>
                        <path d="M26.8056303,0 L3.82926282,0 C1.71559305,0.00283086014 0.00283086014,1.71559305 0,3.82926282 L0,21.0615385 C0.00283086014,23.1752082 1.71559305,24.8879704 3.82926282,24.8908013 L9.5733547,24.8908013 L9.5733547,29.9170299 C9.57261691,30.1077169 9.64798637,30.2908182 9.7827492,30.4257295 C9.91751202,30.5606407 10.1005303,30.6362117 10.2912179,30.6356999 C10.4449308,30.6367383 10.594557,30.5862134 10.7161645,30.4921902 L18.1892949,24.8908013 L26.8068162,24.8908013 C28.9203316,24.8877527 30.6328534,23.1750542 30.6356838,21.0615385 L30.6356838,3.82926282 C30.6328519,1.71528485 28.9196089,0.00239540006 26.8056303,0 Z M28.7204594,21.0615385 C28.7174198,22.1178077 27.8618996,22.9733279 26.8056303,22.9763675 L17.5493056,22.9763675 L17.0405556,23.3594124 L11.4881838,27.5234936 L11.4881838,22.9775534 L3.82926282,22.9775534 C2.77268654,22.9742937 1.91721646,22.1181171 1.91482906,21.0615385 L1.91482906,3.82926282 C1.91808454,2.77330165 2.77330165,1.91808454 3.82926282,1.91482906 L26.8056303,1.91482906 C27.8618996,1.9178687 28.7174198,2.77338885 28.7204594,3.82965812 L28.7204594,21.0615385 Z" id="comment-alt-minus-light-2"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'TranslateIcon',
}
</script>