<template>
    <div class='section' id="contact">
      <v-container
          class='container pt-7'
          fluid
      >
          <div class='section__header-container'>
              <v-row justify='center'>
                  <v-col xl='5' lg='8' md='8' cols='12' class='text-center'>
                      <h2 class='mb-4'>Curious? Get in touch</h2>
                      <p class='pb-4'>Get in touch to book a product demo, or get a quote. Our team would be happy to talk through our services and find the perfect solution for your needs.</p>
                      <v-btn
                          depressed
                          large
                          class="white--text btn-equifax"
                      >
                          Contact sales
                      </v-btn>
                  </v-col>
              </v-row>
          </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'CallToAction',
}
</script>
