<template>
    <div>
        <v-app-bar
            elevate-on-scroll
            color='white'
            app
        >
            <v-container
                class='container pt-11 mb-6'
                fluid
            >
                <v-row
                    align='center'
                >
                    <v-col xl='3' lg='3' cols='6' class='pl-0'>
                        <v-row>
                            <v-col xl='12' lg='12' col='12'>
                                <div class='d-flex align-center'>
                                    <div class='d-flex align-center justify-start'>
                                        <div>
                                            <v-img
                                                src='../assets/equifax-engage-logo.png'
                                                height='40'
                                                width='200'
                                                contain
                                            ></v-img>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col xl='6' lg='6' class='navigation__links d-xl-flex d-lg-flex d-md-none d-sm-none d-none justify-center align-center'>
                        <a href='#' class='pr-8' @click='$vuetify.goTo("#solutions")'>Solutions</a>
                        <a href='#' class='pr-8' @click='$vuetify.goTo("#enterprise-sms")'>Enterprise SMS</a>
                        <!-- <a href='#' class='pr-8' @click='$vuetify.goTo("#services")'>Services</a> -->
                        <a href='#' class='pr-8' @click='$vuetify.goTo("#capabilities")'>Capabilities</a>
                        <!-- <a href='#' class='pr-8' @click='$vuetify.goTo("#about")'>About</a> -->
                    </v-col>
                    <v-col xl='3' lg='3' cols='6'>
                        <div>
                            <div class='d-xl-flex d-lg-flex d-md-flex d-sm-none d-none justify-end align-center'>
                                <v-btn
                                    class='ma-2 btn-equifax--outline'
                                    outlined
                                    depressed
                                >
                                    Log in
                                </v-btn>
                                <v-btn
                                    class='ma-2 white--text btn-equifax'
                                    depressed
                                    href='#contact'
                                >
                                    Contact sales
                                </v-btn>
                                <v-app-bar-nav-icon class='d-xl-none d-lg-none' @click='drawer = true'></v-app-bar-nav-icon>
                            </div>
                            <div class='d-xl-none d-lg-none d-md-none d-sm-flex d-flex justify-end align-center'>
                                <v-app-bar-nav-icon @click='drawer = true'></v-app-bar-nav-icon>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>
        <!-- mobile menu -->
        <div class='d-xl-none d-lg-flex d-md-flex d-sm-flex d-flex'>
            <v-navigation-drawer
                class='navigation__drawer container'
                v-model='drawer'
                temporary
                fixed
                right
            >
                <div class='d-flex justify-end pt-3 pb-3'>
                  <v-btn
                      icon
                      @click='drawer = false'
                  >
                      <v-icon>mdi-window-close</v-icon>
                  </v-btn>
                </div>
                <v-list
                    nav
                    dense
                >
                    <v-list-item-group
                        v-model='group'
                    >
                        <template v-for='(point, colIndex) in menuItems'>
                            <v-list-item
                                :key='colIndex'
                                v-model='selection'
                                @click='onMobileMenuItemSelection(point)'
                            >
                                <v-list-item-title>{{ point.title }}</v-list-item-title>
                            </v-list-item>
                        </template>
                    </v-list-item-group>
                </v-list>
                <v-row
                    align='center'
                    class="pl-4 pr-2 pt-4"
                >
                    <v-btn
                        class='ma-2 btn-equifax--outline btn-equifax--mobile'
                        outlined
                        depressed
                    >
                        Log in
                    </v-btn>
                </v-row>
                <v-row class="pl-4 pr-2 pt-2">
                    <v-btn
                        class='ma-2 white--text btn-equifax btn-equifax--mobile'
                        depressed
                    >
                        Contact sales
                    </v-btn>
                </v-row>
            </v-navigation-drawer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
    data: () => ({
        drawer: false,
        group: null,
        selection: null,
        menuItems: [
            {
                icon: 'mdi-check-circle-outline',
                title: 'Solutions',
                id: '#solutions',
            },
            {
                icon: 'mdi-face-agent',
                title: 'Services',
                id: '#services',
            },
            {
                icon: 'mdi-phone-in-talk-outline',
                title: 'Capabilities',
                id: '#capabilities',
            },
            {
                icon: 'mdi-information-outline',
                title: 'About',
                id: '#about',
            },
            {
                title: 'Enterprise SMS',
                id: '#enterprise-sms',
            }
        ],
    }),
    methods: {
        onMobileMenuItemSelection: function(point) {
            this.$vuetify.goTo(point.id);
            this.drawer = false;
            this.selection = -1;
        },
    },
}
</script>

<style lang='scss'>
.navigation {
    &__links {
        & > a {
            text-decoration: none !important;
            font-family: 'Open Sans' !important;
            color: #333e48 !important;
            font-size: 1rem;
            font-weight: 600 !important;
        }
    }
    &__drawer {
        width: 100% !important;

        @include atMedium {
            width: 50% !important;
        }

        @include atLarge {
            width: 25% !important;
        }

        .v-list-item__title {
          font-size: 1.1rem !important;
          line-height: 1.5 !important;
        }
    }
}
</style>
