<template>
    <svg width="62px" height="39px" viewBox="0 0 62 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <title>workflow copy</title>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-913.000000, -226.000000)">
                <g id="workflow-copy" transform="translate(913.000000, 226.000000)">
                    <rect id="Rectangle" stroke="#9E1B32" stroke-width="2" x="23" y="1" width="16" height="10" rx="4"></rect>
                    <rect id="Rectangle-Copy-3" stroke="#9E1B32" stroke-width="2" x="1" y="28" width="16" height="10" rx="4"></rect>
                    <rect id="Rectangle-Copy-4" stroke="#9E1B32" stroke-width="2" x="23" y="28" width="16" height="10" rx="4"></rect>
                    <rect id="Rectangle-Copy-7" stroke="#9E1B32" stroke-width="2" x="45" y="28" width="16" height="10" rx="4"></rect>
                    <rect id="Rectangle" fill="#9E1B32" x="30" y="14" width="2" height="5"></rect>
                    <rect id="Rectangle-Copy-5" fill="#9E1B32" x="8" y="19" width="2" height="6"></rect>
                    <rect id="Rectangle-Copy-6" fill="#9E1B32" x="52" y="19" width="2" height="6"></rect>
                    <rect id="Rectangle-Copy-8" fill="#9E1B32" x="30" y="19" width="2" height="6"></rect>
                    <rect id="Rectangle" fill="#9E1B32" x="8" y="19" width="46" height="2"></rect>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'WorkflowIcon',
}
</script>