export default (y, a, b, r, s) => {
  if (y.yabbr) return;
  r = a.createElement("script");
  r.async = 1;
  r.src = s;
  a.body.appendChild(r);
  r = y.yabbr = function(c, d) {
    r._[c] = d;
  };
  r._ = {
    key: b
  };
};
