<template>
    <div class='section--capabilities' id='capabilities'>
        <v-container
            class='container pt-7'
            fluid
        >
            <div class='section__header-container'>
                <v-row justify='center'>
                    <v-col xl='6' lg="10" sm="10" cols='12'>
                        <h2 class='section__header-title'>A unified communications platform that's built to scale</h2>
                    </v-col>
                </v-row>
                <v-row justify='center'>
                    <v-col xl='8' lg="10" cols='12'>
                        <v-card>
                            <v-simple-table class='capabilities__table'>
                                <template v-slot:default>
                                    <tbody>
                                        <tr
                                            v-for="item in features"
                                            :key="item.name"
                                        >
                                            <td>{{ item.name }}</td>
                                            <td>
                                                <v-icon>mdi-check</v-icon>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'Capabilities',
    data: () => ({
        features: [
            {
                name: 'Aggregation of all chat mediums',
            },
            {
                name: 'Self-cure majority of inbound communications',
            },
            {
                name: '2-way response enhances automation and AI-Bot cure rate'
            },
            {
                name: 'Post integration surveys for Satisfation or NPS scores'
            },
            {
                name: 'Auto-replies based on inbound message',
            },
            {
                name: 'Hold and calendar booking functionality',
            },
            {
                name: 'Reference Client website for auto-replies',
            },
            {
                name: 'Enhanced privacy and anonymity for Customers',
            },
            {
                name: 'Multiple Groups / Agent Skill Queues / Routing Options / Word Based Rules'
            }
        ],
    }),
}
</script>

<style lang='scss'>
.capabilities {
    &__table {
        tbody {
            tr:hover {
               background-color: transparent !important;
            }
         }
    }
}
</style>