<template>
    <div>
        <template v-for='(sellingPointsRow, rowIndex) in this.organisedPoints'>
            <v-row :key='rowIndex'>
                <template v-for='(point, colIndex) in sellingPointsRow'>
                    <v-col :cols='cols' :key='colIndex'>
                        <div :class='colClass'>
                            <div :class='iconClass'>
                                <v-icon :size='vIconSize'>{{ point.icon }}</v-icon>
                            </div>
                            <div class='item__header'>
                                <h3>{{ point.title }}</h3>
                            </div>
                            <div class='item__description'>
                                <p>{{ point.description }}</p>
                            </div>   
                        </div>
                    </v-col>
                </template>
            </v-row>
        </template>
    </div>
</template>

<script>
export default {
    name: 'SellingPoints',
    props: [
        'points',
        'cols',
        'colContainerClass',
        'iconContainerClass',
        'iconSize',
    ],
    data: () => ({
        organisedPoints: [],
        colClass: '',
        iconClass: '',
        vIconSize: 0,
    }),
    beforeMount() {
        this.organisedPoints = this.organisePoints();
        this.colClass = this.colContainerClass ? this.colContainerClass : 'item';
        this.iconClass = this.iconContainerClass ? this.iconContainerClass : 'item__icon';
        this.vIconSize = this.iconSize ? this.iconSize : 40;
    },
    methods: {
        organisePoints: function() {
            // 12 is the maximum amount of columns we can have in a row
            var pointsPerRow = Math.floor(12 / this.cols);
            var rowAmount = Math.round(this.points.length / pointsPerRow);
            var organisedPoints = [];
            var organisedPointsIndex = 0;
            for (var i = 0; i < rowAmount; i++) {
                var nextIndex = organisedPointsIndex + pointsPerRow;
                if (nextIndex >= this.points.length) {
                    organisedPoints.push(this.points.slice(organisedPointsIndex, this.points.length));
                    break;
                } else {
                    organisedPoints.push(this.points.slice(organisedPointsIndex, nextIndex));
                    organisedPointsIndex = nextIndex;
                }
            }
            return organisedPoints;
        }
    }
}
</script>