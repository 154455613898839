<template>
    <div class='page-footer'>
        <v-container
            class='container pt-7 pb-7'
            fluid>
            <v-row>
                <v-col xl='6' lg='6' cols='6' class='px-0'>
                  <a href="https://yabbr.io/">
                    <v-img
                      src='../assets/yabbr-provided-by.png'
                      max-width='100'>
                    </v-img>
                  </a>
                  <v-spacer></v-spacer>
                </v-col>
                <v-col xl='6' lg='6' cols='6' class='page-footer__icon'>
                    <!-- <v-icon x-large>
                        mdi-linkedin
                    </v-icon> -->
                </v-col>
            </v-row>
            <v-row>
                <v-col xl='6' lg='6' cols='12' class='px-0'>
                  <p class='page-footer__text'>Copyright @ 2021 All rights reserved. <a href="https://www.equifax.com.au/privacy">Privacy policy</a></p>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'PageFooter',
}
</script>

<style lang='scss'>
.page-footer {
    box-shadow: 0 0 6px rgb(0 0 0 / 10%);

    &__text {
        color: #777777;
        font-size: 0.75rem;
        padding-top: 1rem;
    }

    &__icon {
        text-align: center;
        @include atLarge {
            display: flex;
            justify-content: flex-end;
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}
</style>